import React from "react";
import { Link } from "gatsby";
import * as styles from "./footer.module.scss";

function Footer() {
  return (
    <footer className={styles.container}>
      <div className={styles.links}>
        <h3>Услуги:</h3>
        <Link to="/" className={styles.link}>
          Главная
        </Link>
        <Link to="/carcarrier" className={styles.link}>
          Автовоз
        </Link>
        <Link to="/indoortowtruck" className={styles.link}>
          Крытый эвакуатор
        </Link>
        <Link to="/minicarcarrier" className={styles.link}>
          Мини автовоз
        </Link>
        <Link to="/transportationofthecar" className={styles.link}>
          Транспортировка авто
        </Link>
        <Link to="/directions" className={styles.link}>
          Актуальные направления
        </Link>
      </div>
      <div className={styles.info}>
        <p>ООО «АВТЭКС»</p>
        <p>
          ИНН 6320065368
          <br />
          ОРГН 1226300010034
        </p>
        <Link to="/privacypolicy">Политика конфиденциальности</Link>
        <p className={styles.heycoddes}>
          Сайт создан командой{" "}
          <a href="https://heycoddes.ru" target="_blank" rel="noreferrer">
            Hey!Coddes
          </a>
        </p>
      </div>
      <div className={styles.cooperation}>
        <h3>Сотрудничество</h3>
        <a
          href="mailto:avtovoz-express@bk.ru"
          target="_blank"
          rel="noreferrer"
          className={styles.link}
        >
          avtovoz-express@bk.ru
        </a>
        <a href="tel:+78005551163" className={styles.link}>
          +7 (800) 555 11 63
        </a>
      </div>
    </footer>
  );
}

export default Footer;
